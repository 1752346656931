<template>
    <div class="container pb-5 pt-lg-8 pt-8">
        <div class="row">
            <div
                class="col-lg-6 product d-flex flex-column justify-content-start border-0 pb-2"
            >
                <div class="card-img-container rounded-4">
                    <img
                        :src="
                            imageUrl
                                ? imageUrl
                                : getProductBySlug($route.params.slug)
                                      ?.primaryImage.url
                        "
                        class="card-img-top"
                        alt="..."
                    />
                </div>
                <div class="d-flex flex-wrap mt-2">
                    <div
                        v-for="image in getProductBySlug($route.params.slug)
                            ?.images"
                        :key="image.id"
                        class="card-second-imgs-container me-2"
                        @click="imageUrl = image.url"
                    >
                        <img :src="image?.url" class="card-second-imgs" />
                    </div>
                </div>
            </div>

            <div class="col-lg-6 product border-0">
                <div class="card-body text-lg-start">
                    <h5 class="card-title text-start mt-sm-2 fw-bold pb-2">
                        {{ getProductBySlug($route.params.slug)?.name }}
                    </h5>
                    <span
                        class="card-text text-start d-flex align-items-center mb-1"
                    >
                        <img
                            v-if="
                                getProductBySlug($route.params.slug)
                                    ?.attributes[0]?.image.url
                            "
                            width="15"
                            height="20"
                            class="ms-1"
                            :src="
                                getProductBySlug($route.params.slug)
                                    ?.attributes[0]?.image.url
                            "
                            alt=""
                        />
                        <div
                            class="value ms-1"
                            v-html="
                                getProductBySlug($route.params.slug)
                                    ?.attributes[0]?.value
                            "
                        ></div>
                        <br />
                    </span>
                    <div class="d-flex align-items-center mt-2">
                        <span
                            class="card-text text-lg-start d-flex align-items-center"
                        >
                            <img
                                v-if="
                                    getProductBySlug($route.params.slug)
                                        ?.attributes[1]?.image.url
                                "
                                width="20"
                                height="20"
                                class="ms-1 me-2"
                                :src="
                                    getProductBySlug($route.params.slug)
                                        ?.attributes[1]?.image.url
                                "
                                alt=""
                            />
                            <div
                                class="value"
                                v-html="
                                    getProductBySlug($route.params.slug)
                                        ?.attributes[1]?.value
                                "
                            ></div>
                        </span>
                        <span
                            class="card-text text-lg-start d-flex align-items-center ms-4"
                        >
                            <img
                                v-if="
                                    getProductBySlug($route.params.slug)
                                        ?.attributes[2]?.image.url
                                "
                                width="20"
                                height="20"
                                class="ms-1 me-2"
                                :src="
                                    getProductBySlug($route.params.slug)
                                        ?.attributes[2]?.image.url
                                "
                                alt=""
                            />
                            <div
                                class="value"
                                v-html="
                                    getProductBySlug($route.params.slug)
                                        ?.attributes[2]?.value
                                "
                            ></div>
                        </span>
                    </div>
                    <div
                        v-html="
                            getProductBySlug(this.$route.params.slug)
                                ?.description
                        "
                        class="pt-2"
                    ></div>
                </div>
            </div>
        </div>

        <!-- related products -->

        <div class="container py-5">
            <h2 class="text-center fw-bold text-primary">
                {{ $t("related-products") }}
            </h2>
            <div
                class="products d-flex gap-4 flex-wrap justify-content-center pt-5"
                @click="imageUrl = null"
            >
                <ProductCard
                    v-for="product in products.filter(
                        (t) => !(t == getProductBySlug(this.$route.params.slug))
                    )"
                    :key="product.id"
                    :product="product"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ProductView',
  data () {
    return {
      imageUrl: null
    }
  },
  components: { ProductCard },
  computed: {
    ...mapState({
      products: (state) => state.products.products
    }),
    ...mapGetters({
      getProductBySlug: 'products/getBySlug'
    })
  },
  created () {
    if (!this.products.length) {
      this.$store.dispatch('products/fetchAll')
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
    .card-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(250, 249, 249);
        height: 500px;
        width: 80%;
    }
    .card-second-imgs-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(250, 249, 249);
        border-radius: 1.4rem;
        height: 180px;
        width: 110px;
        cursor: pointer;
        .card-second-imgs {
            width: 90%;
            aspect-ratio: 0.8;
        }
    }
    .card-img-top {
        width: 80%;
        aspect-ratio: 0.75;
    }
}

.card {
    width: 200px;
    .card-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(250, 249, 249);
        width: 80%;
        aspect-ratio: 0.75;
    }
}
</style>
