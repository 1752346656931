<template>
    <div id="app">
        <PageLoader v-if="loading" />
        <NavBar v-if="!loading" />
        <router-view v-if="!loading" class="pt-lg-7" />
        <FooterVue
            :categories="categories"
            :aboutus="aboutus"
            :branches="branches"
            v-if="!loading"
        />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooterVue from '@/components/FooterVue.vue'
import PageLoader from '@/components/PageLoader.vue'
import { mapState } from 'vuex'
import { changeLang } from '@/i18n/i18n'
import axios from '@/axios'

export default {
  name: 'App',
  components: {
    PageLoader,
    NavBar,
    FooterVue
  },
  data () {
    return {
      loading: true
    }
  },
  metaInfo () {
    return {
      title: 'General' + ' | ' + this.$route.name
    }
  },
  watch: {
    '$i18n.locale' (newLocale) {
      changeLang(newLocale)
      axios.defaults.headers.common['accept-language'] = newLocale
      this.fetchData()

      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  },

  methods: {
    fetchData () {
      this.loading = true
      Promise.all([
        this.$store.dispatch('categories/fetchAll'),
        this.$store.dispatch('aboutus/fetchAll'),
        this.$store.dispatch('products/fetchAll'),
        this.$store.dispatch('contactus/fetchAll')
      ]).finally(() => {
        this.loading = false
      })
    }
  },

  computed: {
    ...mapState({
      categories: (state) => state.categories.categories,
      aboutus: (state) => state.aboutus.aboutus,
      products: (state) => state.products.products,
      branches: (state) => state.contactus.branches
    })
  },

  created () {
    this.fetchData()
  }
}
</script>

<style lang="scss">
#app {
    @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
    font-family: "Montserrat", sans-serif;
    p {
        margin-bottom: 0 !important;
    }
}
</style>
