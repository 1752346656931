<template>
    <div class="home">
        <vueper-slides
            autoplay
            :touchable="true"
            fixed-height="550px"
            slide-image-inside
            no-shadow
        >
            <vueper-slide
                v-for="(slide, i) in sliders"
                :key="i"
                :image="slide.image"
                :title="slide.title"
                :content="slide.content"
            />
        </vueper-slides>

        <!-- ratting section -->
        <section class="ratting py-4">
            <div class="container mx-auto row">
                <div
                    class="col-md-3 col-sm-6 d-flex flex-column justify-content-center align-items-center"
                >
                    <img
                        src="../assets/images/Vector-3.svg"
                        class="pb-4"
                        alt=""
                    />
                    <p class="text-center">
                        {{
                            $t("Covering-all-the-customers-needs-is-our-focus")
                        }}
                    </p>
                </div>
                <div
                    class="col-md-3 col-sm-6 d-flex flex-column justify-content-center align-items-center"
                >
                    <img
                        src="../assets/images/Vector-1.svg"
                        class="pb-4"
                        alt=""
                    />
                    <p class="text-center">
                        {{
                            $t(
                                "Supplying-high-quality-parts-for-the-latest-models"
                            )
                        }}
                    </p>
                </div>
                <div
                    class="col-md-3 col-sm-6 d-flex flex-column justify-content-center align-items-center"
                >
                    <img
                        src="../assets/images/Vector.svg"
                        class="pb-4"
                        alt=""
                    />
                    <p class="text-center">
                        {{ $t("Everything-you-need-in-one-place.") }}
                    </p>
                </div>
                <div
                    class="col-md-3 col-sm-6 d-flex flex-column justify-content-center align-items-center"
                >
                    <img
                        src="../assets/images/Vector-2.svg"
                        class="pb-4"
                        alt=""
                    />
                    <p class="text-center">
                        {{
                            $t(
                                "Many-years-of-experience-in-identifying-our-customers-needs"
                            )
                        }}
                    </p>
                </div>
            </div>
        </section>

        <!-- products -->
        <section class="container py-5">
            <div class="text-center">
                <h2 class="mb-5 fw-bold text-primary">
                    {{ $t("our-products") }}
                </h2>
                <div class="row">
                    <div
                        class="col-md-6 offset-md-3 d-flex justify-content-center"
                    >
                        <router-link
                            v-for="category in categories.slice(0, 2)"
                            :key="category.id"
                            :to="{
                                name: 'products',
                                query: { category: category.slug },
                            }"
                            class="btn btn-outline-primary rounded-4 ms-3 px-4 py-2"
                            >{{ category.name }}</router-link
                        >
                    </div>
                </div>
            </div>
            <div
                class="products d-flex gap-4 flex-wrap justify-content-center pt-5"
            >
                <ProductCard
                    v-for="product in filteredProducts"
                    :key="product.id"
                    :product="product"
                />
            </div>
            <div
                v-if="filteredProducts.length ? false : true"
                class="text-center"
            >
                <h2 class="text-primary fw-bold">No Result</h2>
                <img
                    src="../assets/images/no-results.png"
                    class="text-center w-25"
                    alt=""
                />
            </div>
            <div class="text-center">
                <router-link
                    :to="{ name: 'products' }"
                    class="btn btn-primary text-white px-5 py-2 rounded-4 mt-5 me-2"
                    >{{ $t("see-all-products") }}</router-link
                >
                <button
                    class="btn btn-primary text-white px-5 py-2 rounded-4 mt-5"
                    @click="openPdf()"
                >
                    {{ $t("check-out-our-catalogue") }}
                </button>
            </div>
        </section>

        <!-- contact us -->
        <section class="contact-us container py-4">
            <h2 class="mb-5 text-primary text-center fw-bold">
                {{ $t("contact-us") }}
            </h2>
            <form class="px-4" @submit.prevent="validateForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group text-start">
                            <label class="label">{{ $t("full-name") }}</label>
                            <input
                                type="text"
                                v-model="fullName"
                                class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                                :class="{ 'is-invalid': errors.fullName }"
                                :placeholder="$t('enter-your-name')"
                            />
                            <div v-if="errors.fullName" class="text-danger">
                                {{ errors.fullName }}
                            </div>
                        </div>
                        <div class="form-group text-start">
                            <label class="label">{{ $t("email") }}</label>
                            <input
                                type="email"
                                v-model="email"
                                class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                                :class="{ 'is-invalid': errors.email }"
                                :placeholder="$t('enter-your-email')"
                            />
                            <div v-if="errors.email" class="text-danger">
                                {{ errors.email }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group text-start">
                            <label class="label">{{
                                $t("phone-number")
                            }}</label>
                            <input
                                type="text"
                                v-model="phoneNumber"
                                class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                                :class="{ 'is-invalid': errors.phoneNumber }"
                                :placeholder="$t('enter-your-phone-number')"
                            />
                            <div v-if="errors.phoneNumber" class="text-danger">
                                {{ errors.phoneNumber }}
                            </div>
                        </div>
                        <div class="form-group text-start">
                            <label class="label">{{ $t("type") }}</label>
                            <input
                                type="text"
                                v-model="messageType"
                                class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                                :class="{ 'is-invalid': errors.messageType }"
                                :placeholder="
                                    $t('choose-the-type-of-your-message')
                                "
                            />
                            <div v-if="errors.messageType" class="text-danger">
                                {{ errors.messageType }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group text-start">
                        <label class="label">{{ $t("message") }}</label>
                        <textarea
                            v-model="message"
                            class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                            :class="{ 'is-invalid': errors.message }"
                            :placeholder="$t('enter-your-message')"
                        ></textarea>
                        <div v-if="errors.message" class="text-danger">
                            {{ errors.message }}
                        </div>
                        <div class="text-center">
                            <button
                                type="submit"
                                class="btn btn-primary rounded-4 text-white mb-4 px-5 py-2"
                            >
                                {{ $t("send") }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </section>

    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { mapState } from 'vuex'
import ProductCard from '../components/ProductCard.vue'

export default {
  name: 'HomeView',
  components: { VueperSlides, VueperSlide, ProductCard },
  data () {
    return {
      fullName: '',
      email: '',
      phoneNumber: '',
      messageType: '',
      message: '',
      errors: {},
      searchString: '',
      pdfUrl: null
    }
  },
  methods: {
    validateForm () {
      this.errors = {}

      if (!this.fullName) {
        this.errors.fullName = this.$t('full-name-is-required')
      }
      if (!this.email) {
        this.errors.email = this.$t('email-is-required')
      } else if (!this.isValidEmail(this.email)) {
        this.errors.email = this.$t('invalid-email-address')
      }
      if (!this.phoneNumber) {
        this.errors.phoneNumber = this.$t('phone-number-is-required')
      } else if (!this.isValidPhoneNumber(this.phoneNumber)) {
        this.errors.phoneNumber = this.$t('invalid-phone-number')
      }
      if (!this.messageType) {
        this.errors.messageType = this.$t('message-type-is-required')
      }
      if (!this.message) {
        this.errors.message = this.$t('message-is-required')
      }

      if (Object.keys(this.errors).length === 0) {
        this.$store.dispatch('contactus/sendFeedback', {
          name: this.name,
          email: this.email,
          type: this.type,
          body: this.message
        })
      }
    },
    isValidEmail (email) {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      return re.test(email)
    },
    isValidPhoneNumber (phoneNumber) {
      const re = /^[0-9]{10,15}$/ // Simple phone number validation
      return re.test(phoneNumber)
    },
    openPdf () {
      const pdfUrl = 'https://general-pompe.com/files/file.pdf'
      window.open(pdfUrl, '_blank')
    }
  },
  computed: {
    ...mapState({
      sliders: (state) => state.sliders.sliders,
      products: (state) => state.products.products,
      categories: (state) => state.categories.categories
    }),

    filteredProducts () {
      return this.products
        .filter((product) =>
          product.name
            .toLocaleLowerCase()
            .includes(this.searchString.toLocaleLowerCase())
        )
        .filter((product) =>
          this.$route.query.category
            ? product.categoriesIds.includes(
              this.getCategoriesBySlug(
                this.$route.query.category
              ).id
            )
            : true
        )
    }
  },
  created () {
    if (!this.sliders.length) {
      this.$store.dispatch('sliders/fetchAll')
    }
    if (!this.products.length) {
      this.$store.dispatch('products/fetchAll')
    }
    if (!this.categories.length) {
      this.$store.dispatch('categories/fetchAll')
    }
  }
}
</script>

<style lang="scss" scoped>
// ratting
.ratting {
    background-color: #e1eff2;
}
// products
.search {
    width: 35%;
}
.card {
    width: 200px;
    .card-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(250, 249, 249);
        height: 300px;
        width: 100%;
    }
    .card-img-top {
        // width: 75px;
        height: 250px;
        width: 75px;
    }
}

input::placeholder {
    color: #b9b9b9;
}
textarea::placeholder {
    color: #b9b9b9;
}
.btn-outline-primary:hover {
    color: white;
}
</style>

<style lang="scss">
// slider style
.vueperslides__bullet .default {
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    box-shadow: none;
    transition: 0.3s;
    width: 13px;
    height: 13px;
    margin-bottom: 1rem;
}

.vueperslides__bullet--active .default {
    background-color: var(--bs-secondary);
}

.vueperslides__bullet span {
    display: block;
    color: #fff;
    font-size: 10px;
    opacity: 0.8;
}

.vueperslide__image {
    z-index: -1;
}
</style>
