var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer bg-info pt-5"},[_c('div',{staticClass:"container"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/images/logo.svg"),"width":"22%","alt":""}})]),_c('div',{staticClass:"row pt-5"},[_c('div',{staticClass:"col-md-4 d-flex align-items-center"},[_c('p',{staticClass:"text-success text-start fw-light",domProps:{"innerHTML":_vm._s(
                        _vm.aboutus
                            .find((t) => t.key === 'about')
                            ?.value.slice(0, 300)
                    )}})]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row ms-lg-6"},[_c('div',{staticClass:"col-md-3 col-6"},[_c('h5',{staticClass:"text-start text-white fs-6 pb-3"},[_vm._v(" "+_vm._s(_vm.$t("site-map"))+" ")]),_c('ul',{staticClass:"list-unstyled text-start"},[_c('li',{staticClass:"mb-4"},[_c('router-link',{staticClass:"link text-decoration-none text-success fw-light",attrs:{"to":{ name: 'home' }}},[_vm._v(_vm._s(_vm.$t("home")))])],1),_c('li',{staticClass:"mb-4"},[_c('router-link',{staticClass:"link text-decoration-none text-success fw-light",attrs:{"to":{ name: 'about' }}},[_vm._v(" "+_vm._s(_vm.$t("about"))+" ")])],1),_c('li',{staticClass:"mb-4"},[_c('router-link',{staticClass:"link text-decoration-none text-success fw-light",attrs:{"to":{ name: 'products' }}},[_vm._v(" "+_vm._s(_vm.$t("products"))+" ")])],1),_c('li',{staticClass:"mb-4"},[_c('router-link',{staticClass:"link text-decoration-none text-success fw-light",attrs:{"to":{ name: 'contact-us' }}},[_vm._v(" "+_vm._s(_vm.$t("contact"))+" ")])],1)])]),_c('div',{staticClass:"col-md-3 col-6"},[_c('h1',{staticClass:"text-start text-white fs-6 pb-3"},[_vm._v(" Categories ")]),_c('ul',{staticClass:"list-unstyled text-start"},_vm._l((_vm.categories),function(category){return _c('li',{key:category.id,staticClass:"mb-4"},[_c('router-link',{staticClass:"link text-decoration-none text-success fw-light",attrs:{"to":{
                                        name: 'products',
                                        query: { category: category.slug },
                                    }}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)}),0)]),_c('div',{staticClass:"col-md-3 col-6 p-0"},[_c('h5',{staticClass:"text-start text-white fs-6 pb-3"},[_vm._v(" "+_vm._s(_vm.$t("contact-us"))+" ")]),_vm._l((_vm.branches),function(branch){return _c('ul',{key:branch.id,staticClass:"list-unstyled text-start"},[_c('li',{staticClass:"mb-4 text-success fw-light"},[_vm._v(" "+_vm._s(branch.contacts.email)+" ")]),_c('li',{staticClass:"mb-4 text-success fw-light"}),_c('li',{staticClass:"mb-4 text-success fw-light"},[_vm._v(" "+_vm._s(branch.contacts.address)+" ")])])})],2)])])]),_vm._m(0),_c('div',{staticClass:"copy-right text-center py-3 text-white fw-lighter"},[_c('span',[_vm._v(" "+_vm._s(new Date().getFullYear())+" © Matador")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-bottom border-success d-flex justify-content-between"},[_c('div',{staticClass:"icons pb-4"},[_c('span',[_c('a',{attrs:{"href":"https://facebook.com","target":"_blank"}},[_c('i',{staticClass:"link text-success pe-5 opacity-75 fa-brands fa-facebook"})])]),_c('span',[_c('a',{attrs:{"href":"https://instagram.com","target":"_blank"}},[_c('i',{staticClass:"link text-success pe-5 opacity-75 fa-brands fa-instagram"})])]),_c('span',[_c('a',{attrs:{"href":"https://twitter.com","target":"_blank"}},[_c('i',{staticClass:"link text-success pe-5 opacity-75 fa-brands fa-x-twitter"})])]),_c('span',[_c('a',{attrs:{"href":"https://linkedin.com","target":"_blank"}},[_c('i',{staticClass:"link text-success pe-5 opacity-75 fa-brands fa-linkedin"})])])]),_c('div',{staticClass:"language"},[_c('button',{staticClass:"flag btn btn-outline-inherit border-0 p-0 ms-2",attrs:{"type":"submit"}},[_c('img',{staticClass:"img-flag",attrs:{"src":require("../assets/images/flag/flag-united-state.png"),"width":"100%","alt":"EN"}})]),_c('button',{staticClass:"flag btn btn-outline-inherit border-0 p-0 ms-2",attrs:{"type":"submit"}},[_c('img',{staticClass:"img-flag",attrs:{"src":require("../assets/images/flag/flag-italian.png"),"width":"100%","alt":"ES"}})])])])
}]

export { render, staticRenderFns }