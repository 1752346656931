<template>
    <router-link
        :to="{ name: 'product', params: { slug: product.slug } }"
        class="card-hover card border-0 text-decoration-none"
    >
        <div class="card-img-container rounded-4">
            <img
                :src="product?.primaryImage.url"
                class="card-img-top"
                alt="..."
            />
        </div>
        <div class="card-body text-lg-start text-center">
            <h5 class="card-title text-lg-start fw-bold">{{ product.name }}</h5>
            <span
                class="card-text text-lg-start d-flex align-items-center mb-1"
            >
                <img
                    v-if="product.attributes[0]?.image.url"
                    width="15"
                    height="20"
                    class="ms-1"
                    :src="product.attributes[0]?.image.url"
                    alt=""
                />
                <div
                    class="value ms-1"
                    v-html="product.attributes[0]?.value"
                ></div>
                <br />
            </span>
            <div class="d-flex align-items-center mt-2">
                <span class="card-text text-lg-start d-flex align-items-center">
                    <img
                        v-if="product.attributes[1]?.image.url"
                        width="20"
                        height="20"
                        class="ms-1 me-2"
                        :src="product.attributes[1]?.image.url"
                        alt=""
                    />
                    <div
                        class="value"
                        v-html="product.attributes[1]?.value"
                    ></div>
                </span>
                <span
                    class="card-text text-lg-start d-flex align-items-center ms-4"
                >
                    <img
                        v-if="product.attributes[2]?.image.url"
                        width="20"
                        height="20"
                        class="ms-1 me-2"
                        :src="product.attributes[2]?.image.url"
                        alt=""
                    />
                    <div
                        class="value"
                        v-html="product.attributes[2]?.value"
                    ></div>
                </span>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: ['product']
}
</script>

<style lang="scss">
.card-hover:hover {
    animation: translate 0.2s ease forwards;
}
@keyframes translate {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}
.card {
    width: 200px;
    .card-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(250, 249, 249);
        height: 300px;
        width: 100%;
    }
    .card-img-top {
        width: 80%;
        aspect-ratio: 0.75;
    }
    .value {
        :first-child {
            margin: 0;
            font-size: 13px;
        }
    }
}
</style>
