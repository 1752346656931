<template>
    <nav class="navbar navbar-dark navbar-expand-lg fixed-top">
        <div class="container d-flex">
            <router-link class="navbar-brand" :to="{ name: 'home' }"
                ><img width="75%" src="../assets/images/logo.svg" alt="logo"
            /></router-link>
            <button
                :class="['navbar-toggler', { collapsed: isNavbarOpen }]"
                @click="isNavbarOpen = !isNavbarOpen"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                :aria-expanded="isNavbarOpen ? 'true' : 'false'"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div
                :class="['collapse', 'navbar-collapse', { show: isNavbarOpen }]"
                id="navbarNavAltMarkup"
            >
                <ul class="navbar-nav mx-auto d-flex flex-row my-3">
                    <li class="nav-item me-4" @click="isNavbarOpen = false">
                        <router-link
                            class="nav-link text-white"
                            :to="{ name: 'home' }"
                            >{{ $t("home") }}</router-link
                        >
                    </li>
                    <li class="nav-item me-4" @click="isNavbarOpen = false">
                        <router-link
                            class="nav-link text-white"
                            :to="{ name: 'about' }"
                            >{{ $t("about") }}</router-link
                        >
                    </li>
                    <li class="nav-item me-4" @click="isNavbarOpen = false">
                        <router-link
                            class="nav-link text-white"
                            :to="{ name: 'products' }"
                            >{{ $t("products") }}</router-link
                        >
                    </li>
                    <li class="nav-item me-4" @click="isNavbarOpen = false">
                        <router-link
                            class="nav-link text-white"
                            :to="{ name: 'contact-us' }"
                            >{{ $t("contact") }}</router-link
                        >
                    </li>
                </ul>
                <span class="me-5"
                    ><i class="fas fa-search text-white fa-lg"></i
                ></span>
                <button
                    @click="changeLocale('en')"
                    :class="{ active: activeLang === 'en' }"
                    class="flag btn btn-outline-inherit border-0 p-0 ms-2"
                    type="submit"
                >
                    <img
                        src="../assets/images/flag/flag-united-state.png"
                        width="100%"
                        class="img-flag"
                        alt="EN"
                    />
                </button>
                <button
                    @click="changeLocale('es')"
                    :class="{ active: activeLang === 'es' }"
                    class="flag btn btn-outline-inherit border-0 p-0 ms-2"
                    type="submit"
                >
                    <img
                        src="../assets/images/flag/flag-italian.png"
                        width="100%"
                        class="img-flag"
                        alt="ES"
                    />
                </button>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
  name: 'NavBar',
  data () {
    return {
      isNavbarOpen: false,
      activeLang: '',
      loading: false
    }
  },
  methods: {
    changeLocale (locale) {
      this.$i18n.locale = locale
      this.activeLang = locale
      localStorage.setItem('locale', locale)
    }
  },

  mounted () {
    const savedLocale = localStorage.getItem('locale')
    if (savedLocale) {
      this.$i18n.locale = savedLocale
      this.activeLang = savedLocale
    }
  }
}
</script>

<style lang="scss">
.navbar {
    background: rgb(31, 115, 148);
    background: linear-gradient(
        264deg,
        rgba(31, 115, 148, 1) 0%,
        rgba(39, 122, 161, 1) 28%,
        rgba(53, 134, 184, 1) 55%,
        rgba(53, 134, 184, 1) 100%
    );
    .navbar-nav {
        .nav-link {
            border-bottom: solid 2px transparent;
            &:hover {
                border-bottom: solid 2px white;
            }
            &.router-link-exact-active {
                border-bottom: solid 2px white;
            }
        }
    }

    .flag {
        width: 25px;
    }
    .active {
        .img-flag {
            filter: drop-shadow(2px 0px 0px #48d0fa)
                drop-shadow(-2px 0px 0px #48d0fa)
                drop-shadow(0px 2px 0px #48d0fa)
                drop-shadow(0px -2px 0px #48d0fa);
        }
    }
}
</style>
