<template>
    <footer class="footer bg-info pt-5">
        <div class="container">
            <router-link :to="{ name: 'home' }">
                <img
                    src="../assets/images/logo.svg"
                    width="22%"
                    class="logo"
                    alt=""
                />
            </router-link>
            <div class="row pt-5">
                <div class="col-md-4 d-flex align-items-center">
                    <p
                        v-html="
                            aboutus
                                .find((t) => t.key === 'about')
                                ?.value.slice(0, 300)
                        "
                        class="text-success text-start fw-light"
                    ></p>
                </div>
                <div class="col-md-8">
                    <div class="row ms-lg-6">
                        <div class="col-md-3 col-6">
                            <h5 class="text-start text-white fs-6 pb-3">
                                {{ $t("site-map") }}
                            </h5>
                            <ul class="list-unstyled text-start">
                                <li class="mb-4">
                                    <router-link
                                        :to="{ name: 'home' }"
                                        class="link text-decoration-none text-success fw-light"
                                        >{{ $t("home") }}</router-link
                                    >
                                </li>
                                <li class="mb-4">
                                    <router-link
                                        :to="{ name: 'about' }"
                                        class="link text-decoration-none text-success fw-light"
                                    >
                                        {{ $t("about") }}
                                    </router-link>
                                </li>
                                <li class="mb-4">
                                    <router-link
                                        :to="{ name: 'products' }"
                                        class="link text-decoration-none text-success fw-light"
                                    >
                                        {{ $t("products") }}
                                    </router-link>
                                </li>
                                <li class="mb-4">
                                    <router-link
                                        :to="{ name: 'contact-us' }"
                                        class="link text-decoration-none text-success fw-light"
                                    >
                                        {{ $t("contact") }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-3 col-6">
                            <h1 class="text-start text-white fs-6 pb-3">
                                Categories
                            </h1>
                            <ul class="list-unstyled text-start">
                                <li
                                    v-for="category in categories"
                                    :key="category.id"
                                    class="mb-4"
                                >
                                    <router-link
                                        :to="{
                                            name: 'products',
                                            query: { category: category.slug },
                                        }"
                                        class="link text-decoration-none text-success fw-light"
                                    >
                                        {{ category.name }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-3 col-6 p-0">
                            <h5 class="text-start text-white fs-6 pb-3">
                                {{ $t("contact-us") }}
                            </h5>
                            <ul
                                v-for="branch in branches"
                                :key="branch.id"
                                class="list-unstyled text-start"
                            >
                                <li class="mb-4 text-success fw-light">
                                    {{ branch.contacts.email }}
                                </li>
                                <li class="mb-4 text-success fw-light"></li>
                                <li class="mb-4 text-success fw-light">
                                    {{ branch.contacts.address }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Follow Us -->
            <div
                class="border-bottom border-success d-flex justify-content-between"
            >
                <div class="icons pb-4">
                    <span
                        ><a href="https://facebook.com" target="_blank"
                            ><i
                                class="link text-success pe-5 opacity-75 fa-brands fa-facebook"
                            ></i></a
                    ></span>
                    <span
                        ><a href="https://instagram.com" target="_blank"
                            ><i
                                class="link text-success pe-5 opacity-75 fa-brands fa-instagram"
                            ></i></a
                    ></span>
                    <span
                        ><a href="https://twitter.com" target="_blank"
                            ><i
                                class="link text-success pe-5 opacity-75 fa-brands fa-x-twitter"
                            ></i></a
                    ></span>
                    <span
                        ><a href="https://linkedin.com" target="_blank"
                            ><i
                                class="link text-success pe-5 opacity-75 fa-brands fa-linkedin"
                            ></i></a
                    ></span>
                </div>
                <div class="language">
                    <button
                        class="flag btn btn-outline-inherit border-0 p-0 ms-2"
                        type="submit"
                    >
                        <img
                            src="../assets/images/flag/flag-united-state.png"
                            width="100%"
                            class="img-flag"
                            alt="EN"
                        />
                    </button>

                    <button
                        class="flag btn btn-outline-inherit border-0 p-0 ms-2"
                        type="submit"
                    >
                        <img
                            src="../assets/images/flag/flag-italian.png"
                            width="100%"
                            class="img-flag"
                            alt="ES"
                        />
                    </button>
                </div>
            </div>

            <div class="copy-right text-center py-3 text-white fw-lighter">
                <span> {{ new Date().getFullYear() }} &copy; Matador</span>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'FooterVue',
  data () {
    return {}
  },
  props: ['categories', 'aboutus', 'branches']
}
</script>

<style lang="scss">
.flag {
    width: 25px;
}
.active {
    .img-flag {
        filter: drop-shadow(2px 0px 0px #48d0fa)
            drop-shadow(-2px 0px 0px #48d0fa) drop-shadow(0px 2px 0px #48d0fa)
            drop-shadow(0px -2px 0px #48d0fa);
    }
}
.copy-right {
    font-size: 11px;
}
.link:hover {
    color: white !important;
}
@media (max-width: 786px) {
    .logo {
        width: 40%;
    }
}
</style>
